<template>
  <div :style="computedStyle" v-show="!isHidden">
    <el-dropdown @command="action" :style="'width:' + width" :size="size">
      <el-button :type="type" :style="computedStyle" :disabled="isDisabled" >
        {{label}}<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown" >
        <template v-for="(item, index) of computedMenu" >
          <el-tooltip effect="dark" :key="index" :content="item.tooltip" placement="right" v-if="item.tooltip">
            <el-dropdown-item :icon="item.icon" :key="index" :command="item" :disabled="readonly && !item.isAllow">{{item.name}}</el-dropdown-item>
          </el-tooltip>
          <el-dropdown-item v-else :icon="item.icon" :key="index" :command="item" :disabled="readonly && !item.isAllow">{{item.name}}</el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import Dashboard from '@/components/Dashboard'
export default {
  name: 'a-dropdown',
  components: {
    Dashboard
  },
  mixins: [mixin, registryMixin],
  inject: {
    getParentContext: {
      default: () => {}
    },
    getModel: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    openRegistryCard: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    }
  },
  props: {
    label: {
      description: 'Название',
      type: String
    },
    type: {
      type: String,
      description: 'Тип'
    },
    downMenu: {
      type: Array,
      description: 'Редактировать пункты',
      editor: 'DropDownMenu',
      default: () => []
    }
  },
  data () {
    return {
      downItems: []
    }
  },
  computed: {
    isDisabled () {
      if (this.readonly) {
        return this.downItems.filter((item) => item.isAllow).length === 0
      }
      return false
    },
    computedMenu () {
      return this.downItems
        .map(item => { return { ...item, isHide: this.checkConditions(item.isHidden) } })
        .filter(item => !item.isHide)
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (!this.block) {
        css += ';display: inline-block'
      }

      return css
    }
  },
  methods: {
    dataFilters (filter) {
      let filters = []
      if (filter) {
        filter.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              filters.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            filters.push({
              key: item.alias,
              value: item.attribute
            })
          }
        })
      }
      return filters
    },
    getDefaultsForCard (command) {
      let defaults = []
      if (command.card.defaults) {
        command.card.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }
      return defaults
    },
    action (command) {
      if (command.type === 'execute_plugin') {
        if (!command.plugin) {
          console.log(`Plugin doesn't set`)
          return false
        }
          import(`@/plugins/${this.$config.project}/${command.plugin}`)
            .then((Plugin) => {
              let instance = new Plugin.default(this)
              instance.execute()
            }).catch(() => {
              console.log(`Plugin: ${command.plugin} not found`)
            })
      } else if (command.type === 'open_dashboard' && command.dashboard.id) {
        if (command.dashboard.isFullscreen) {
          this.openDashboardCard(command.dashboard.id, command.dashboard.window_title || '')
        } else {
          const h = this.$createElement
          let customClass = 'custom_scrollbar '
          if (command.dashboard.window_width) {
            customClass += `dashboard_window_width_${command.dashboard.window_width}`
          }
          this.$msgbox({
            title: command.dashboard.window_title,
            customClass: customClass,
            message: h('dashboard', {
              props: {
                id: command.dashboard.id,
                parentContext: this,
                model: JSON.parse(JSON.stringify(this.getModel()))
              },
              key: this.generateGuid()
            }),
            showCancelButton: false,
            showConfirmButton: false,
            closeOnClickModal: false
          })
        }
      } else if (command.type === 'open_report' && command.reports.id) {
        let filter = this.dataFilters(command.filters)
        console.log(filter)
        this.addMainTab({ name: command.reports.name,
          componentType: 'StimulsoftViewer',
          payload: {
            filename: `${command.reports.guid}.mrt`,
            variables: filter
          } })
      } else if (command.type === 'execute_command' && command.command.id) {
        this.getCard().saveRecord({ commandId: command.command.id }).then((response) => {
          this.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: command.command.success_text,
            type: 'success'
          })
        }).catch((error) => {
          console.error(error)
          this.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: command.command.failure_text,
            type: 'error'
          })
        })
      } else if (command.type === 'open_card') {
        if (
          !command.card.registryId ||
          !command.card.type ||
          (!command.card.fieldId && !command.card.constantRecordId) ||
          !command.card.cardId
        ) {
          console.warn('wrong parameters', command.card)
          return false
        }
        const readOnly = command.card.type === 'read'
        let recordId = null
        let initialData = {}
        let defaults = this.getDefaultsForCard(command)
        defaults.forEach((item) => {
          initialData[item.key] = item.value
        })
        if (command.card.type === 'update' || command.card.type === 'read') {
          if (command.card.constantRecordId) {
            recordId = command.card.constantRecordId
          } else {
            recordId = this.getModel()[command.card.fieldId]
          }
          if (!recordId) {
            console.warn(`recordId doesn't set = ${command.card.fieldId}, constant = ${command.card.constantRecordId}`)
            return false
          }
        } else if (command.card.type === 'add') {
          if (command.card.fieldId) {
            if (!this.getModel()['id']) {
              console.warn(`record has not id`)
              return false
            }
            let fieldId = command.card.fieldId
            let outerXrefComponent = Object.values(this.getDashboardComponents()).find(function (item) {
              return item[0].name === fieldId
            })

            if (!outerXrefComponent || !outerXrefComponent[0].outerXrefId) {
              console.warn(`outer_xref does't set`)
              return false
            }

            initialData[`attr_${outerXrefComponent[0].outerXrefId}_`] = this.getModel()['id']
          }
        }
        if (command.card.isWindow) {
          const h = this.$createElement
          let customClass = 'custom_scrollbar '
          if (command.card.windowWidth) {
            customClass += `dashboard_window_width_${command.card.windowWidth}`
          }
          this.$msgbox({
            title: command.card.windowTitle,
            customClass: customClass,
            message: h('registry-card', { props: {
              cardId: command.card.cardId,
              registryId: command.card.registryId,
              readonly: readOnly,
              recordId: recordId,
              initialData: initialData
            },
            key: this.generateGuid() }),
            showCancelButton: false,
            showConfirmButton: false,
            closeOnClickModal: false
          })
        } else {
          this.openRegistryCard({
            registryId: command.card.registryId,
            cardId: command.card.cardId,
            cardName: '',
            recordId: recordId,
            initialData: initialData,
            registry: { readonly: readOnly, addRecord: () => {}, updateRecord: () => {} }
          })
        }
      } else if ((command.type === 'open_document' || command.type === 'open_xml') && command.reports.id) {
        let filters = this.dataFilters(command.filters)
        this.$http
          .request({
            method: 'post',
            url: `${this.$config.api}/reporteditor/reports/${command.reports.id}/document/${command.reports.formatType}`,
            data: {
              registry_id: command.reports.registryId,
              field_id: command.reports.assocFieldId,
              record_id: this.getModel()['id'],
              record_guid: this.getModel()['guid'],
              is_open_saved_version: command.reports.openSavedVersion,
              filters: filters
            },
            responseType: 'json'
          })
          .then(response => {
            if (command.reports.viewType === 'download') {
              this.$http
                .request({
                  method: 'post',
                  url: `${this.$config.api}/reporteditor/reports/document/download`,
                  data: response.data,
                  responseType: 'blob'
                })
                .then(res => {
                  let formats = {
                    docx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    pdf: 'application/pdf',
                    xml: 'application/xml'
                  }
                  const url = window.URL.createObjectURL(new Blob([res.data], { type: formats[command.reports.formatType] }))
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', `${command.reports.name}.${command.reports.formatType}`)
                  document.body.appendChild(link)
                  link.click()
                  link.remove()
                })
            } else {
              if (command.reports.formatType !== 'xml') {
                this.addMainTab({ name: command.reports.name,
                  componentType: 'DocumentViewer',
                  payload: {
                    guid: this.generateGuid(),
                    filename: `${response.data.name}.${response.data.extension}`,
                    isRegistryFile: response.data.isRegistryFile,
                    reportId: command.reports.id
                  } })
              } else {
                console.log('XmlViewer')
                this.addMainTab({ name: command.reports.name,
                  componentType: 'XmlViewer',
                  payload: {
                    guid: this.generateGuid(),
                    content: response.data.content
                  } })
              }
            }

            console.log('Build and opening document successful is completed!')
          })
      }
    }
  },
  mounted () {
    if (this.downMenu) {
      this.downItems = this.downMenu
    }
  }
}
</script>

<style scoped>
  .el-dropdown-link {
    cursor: pointer;
  }
</style>
